import React, { useState } from "react"
import styled from "styled-components"
import { Container, Row, Col } from "react-grid-system"
import DesignTokens from "./designTokens"
import SectionHeader from "./sectionHeader"
import { HeadingSmall } from "./typography"

const MansionsBlock = ({ content }) => {
  const [onSectionHover, setSectionHover] = useState(false)
  return (
    <Wrapper>
      <Container>
        <Row justify="center">
          <Col lg={9} md={12}>
            <SectionHeader title={content.title} text={content.text} />
          </Col>
        </Row>
        <Row
          onMouseEnter={() => setSectionHover(true)}
          onMouseLeave={() => setSectionHover(false)}
        >
          {content.mansions.map(mansion => (
            <Mansion
              isOnHover={onSectionHover}
              mansion={mansion}
              key={mansion.id}
            />
          ))}
        </Row>
      </Container>
    </Wrapper>
  )
}
const Wrapper = styled.div``

const Mansion = ({ mansion, isOnHover }) => {
  const [onHover, setHover] = useState(false)
  return (
    <MansionWrapper
      md={4}
      xs={6}
      isSectionOnHover={isOnHover}
      isOnHover={onHover}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <a href={mansion.url} target="blank" rel="noopener nofollow">
        <img src={mansion.image.url} alt={mansion.image.alt} />
        <HeadingSmall>{mansion.name}</HeadingSmall>
      </a>
    </MansionWrapper>
  )
}

const MansionWrapper = styled(Col)`
  transition: all 0.25s ease;
  opacity: ${props => props.isSectionOnHover ? 0.6 : 1};
  margin-bottom: 2rem;
  &:hover {
    opacity: 1;
  }
  a {
    text-decoration: none;
    &:hover {
      color: ${DesignTokens.colors.primary[500]};
    }
  }
`

export default MansionsBlock
