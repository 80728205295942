import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "react-grid-system"
import DesignTokens from "./designTokens"
import { HeadingSmall } from "./typography"
import SectionHeader from "./sectionHeader"
import RichContentBlockText from "./richContentBlockText"
import { OutboundLink } from "../../home/link"

const PartnersBlock = ({ content }) => (
  <Wrapper>
    <Container>
      <Row>
        <Col md={12}>
          <SectionHeader title={content.title} />
        </Col>
      </Row>
      <Row>
        {content.partners.map(partner => (
          <Partner md={6} sm={12} key={partner.id}>
            <div>
              <img src={partner.logo.url} alt={partner.logo.alt} />
            </div>
            <HeadingSmall weight="700">{partner.name}</HeadingSmall>
            <RichContentBlockText html={partner.text} />
            <OutboundLink
              href={partner.url}
              target="blank"
              rel="nofollow noopener"
            >
              Scopri di più ⟶
            </OutboundLink>
          </Partner>
        ))}
      </Row>
    </Container>
  </Wrapper>
)
const Wrapper = styled.div``

const Partner = styled(Col)`
  div {
    min-height: 90px;
    margin-bottom: ${DesignTokens.spacing[7]};
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
  h4 {
    margin-bottom: ${DesignTokens.spacing[7]};
  }
  a {
    text-decoration: none;
    font-weight: 700;
    &:hover {
      color: ${DesignTokens.colors.primary[500]};
    }
  }
`

export default PartnersBlock
