import React from "react"
import styled from "styled-components"
import { Display, Heading, Lead, Caption } from "./typography"
import { Container, Row, Col } from "react-grid-system"
import DesignTokens from "./designTokens"

const Intro = ({ page }) => (
  <Wrapper id="header" background={page.heroImage.url}>
    <Container>
      <Row>
        <Col sm={0} md={3} lg={6}>
          {/* <img src={page.heroImage.url} alt={page.heroImage.alt} /> */}
        </Col>
        <Col sm={12} md={9} lg={6}>
          <Box>
            <Caption>{page.label}</Caption>
            <Display>{page.title}</Display>
            <Lead as="h2">{page.subtitle}</Lead>
          </Box>
        </Col>
      </Row>
    </Container>
  </Wrapper>
)
const Wrapper = styled.div`
  background-image: url(${props => props.background});
  background-size: 1200px;
  background-position: top left;
  background-repeat: no-repeat;
  padding: 8rem 0;
  /* color: #fff; */
`

const Box = styled.div`
  background: #33333390;
  background-image: ${DesignTokens.colors.overlay.dark};
  padding: 6rem 2rem;
  color: ${DesignTokens.colors.white};
  @media screen and (max-width: 767px) {
    padding: 3rem 2rem;
  }

  & > * {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default Intro
