import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-grid-system"
import DesignTokens from "./designTokens"
import { HeadingSmall, Body, Caption, CaptionSmall } from "./typography"
import SectionHeader from "./sectionHeader"
import { OutboundLink } from "../../home/link"

const DesignersBlock = ({ content }) => {
  const [onSectionHover, setSectionHover] = useState(false)
  return (
    <Wrapper>
      <Container>
        <Row>
          <Col md={12}>
            <SectionHeader title={content.title} />
          </Col>
        </Row>
        <Row
          onMouseEnter={() => setSectionHover(true)}
          onMouseLeave={() => setSectionHover(false)}
        >
          {content.designers.map(designer => (
            <Designer
              isOnHover={onSectionHover}
              designer={designer}
              key={designer.id}
            />
          ))}
        </Row>
      </Container>
    </Wrapper>
  )
}
const Wrapper = styled.div``

const Designer = ({ designer, isOnHover }) => {
  const [onHover, setHover] = useState(false)
  return (
    <DesignerWrapper
      xxl={3}
      md={4}
      sm={6}
      xs={6}
      isSectionOnHover={isOnHover}
      isOnHover={onHover}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {designer.available ? (
        <OutboundLink
          href={designer.available ? designer.url : null}
          target="blank"
          rel="noopener nofollow"
          disabled={!designer.available}
        >
          <DesignerDetails designer={designer} />
        </OutboundLink>
      ) : (
        <Link to="/newsletter/">
          <DesignerDetails designer={designer} />
        </Link>
      )}
    </DesignerWrapper>
  )
}

const DesignerWrapper = styled(Col)`
  transition: all 0.25s ease;
  opacity: ${props => (props.isSectionOnHover ? 0.6 : 1)};
  margin-bottom: 2rem;
  &:hover {
    opacity: 1;
  }
  a {
    text-decoration: none;
    &:hover {
      h4 {
        color: ${DesignTokens.colors.primary[500]};
      }
    }
  }
`

const DesignerDetails = ({ designer }) => {
  const date = new Date(designer.availableAt)
  const dateTimeFormat = new Intl.DateTimeFormat("it", {
    year: "numeric",
    month: "long",
    day: "2-digit",
  }).format(date)
  return (
    <>
      <img src={designer.image.url} alt={designer.image.alt} />
      <HeadingSmall>{designer.name}</HeadingSmall>
      <Body>
        <strong>{designer.mansion.name}</strong>
      </Body>
      {!designer.available && (
        <CaptionSmall color={DesignTokens.colors.primary[500]}>
          {`Disponible: ` + dateTimeFormat}
        </CaptionSmall>
      )}
    </>
  )
}

export default DesignersBlock
