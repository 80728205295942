import React from "react"
import styled from "styled-components"
import * as Scroll from "react-scroll"
import DesignTokens from "./designTokens"
import logo from "../../../images/logo-dimore.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faInstagram,
  faFacebook,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons"
import { OutboundLink } from "../../home/link"
import { Link } from "gatsby"
import { LinkButton } from "./button"

let ScrollLink = Scroll.Link
let scroll = Scroll.animateScroll

const Nav = () => {
  return (
    <Wrapper>
      <Logo to="header" spy={true} smooth={true} offset={-100} duration={500}>
        <img src={logo} />
      </Logo>
      <div>
        <ScrollLink
          activeClass="active"
          to="section1"
          spy={true}
          smooth={true}
          offset={-150}
          duration={500}
        >
          . Bergamo
        </ScrollLink>
        <ScrollLink
          activeClass="active"
          to="section2"
          spy={true}
          smooth={true}
          offset={-150}
          duration={500}
        >
          . Dimore storiche
        </ScrollLink>
        <ScrollLink
          activeClass="active"
          to="section3"
          spy={true}
          smooth={true}
          offset={-130}
          duration={500}
        >
          . Puntate con i Designers
        </ScrollLink>
        <ScrollLink
          activeClass="active"
          to="section5"
          spy={true}
          smooth={true}
          offset={-130}
          duration={500}
        >
          . Organizzatori
        </ScrollLink>
        <OutboundLink
          href="https://spark.adobe.com/page/tDHM3DBPUqumP/"
          target="blank"
          rel="nofollow noopener"
        >
          . Press
        </OutboundLink>
      </div>
      <div>
        <LinkButton to="/newsletter/">Iscriviti</LinkButton>
        <OutboundLink
          href="https://www.instagram.com/dimoredesign/"
          target="blank"
          rel="noopener nofollow"
        >
          <FontAwesomeIcon icon={faInstagram} size="lg" />
        </OutboundLink>
        <OutboundLink
          href="https://www.facebook.com/dimoredesign/"
          target="blank"
          rel="noopener nofollow"
        >
          <FontAwesomeIcon icon={faFacebook} size="lg" />
        </OutboundLink>
        <OutboundLink
          href="https://www.youtube.com/channel/UCJAEA6XemGRf6NqxJ154iAg"
          target="blank"
          rel="noopener nofollow"
        >
          <FontAwesomeIcon icon={faYoutube} size="lg" />
        </OutboundLink>
      </div>
    </Wrapper>
  )
}

export default Nav

const Logo = styled(ScrollLink)`
  position: absolute;
  top: 2rem;
  left: 2rem;
`

const Wrapper = styled.nav`
  position: relative;
  padding: ${DesignTokens.spacing[7]} ${DesignTokens.spacing[7]}
    ${DesignTokens.spacing[7]} 11rem;
  background: ${DesignTokens.colors.white};
  width: 100%;
  position: fixed;
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: ${DesignTokens.shadows.floating};
  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    a {
      margin-right: 1.25rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  a {
    cursor: pointer;
    font-weight: 700;
  }
  a:hover,
  a.active {
    color: ${DesignTokens.colors.primary[500]};
  }
`
