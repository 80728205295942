import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Visible } from "react-grid-system"
import Layout from "../components/editions/2020/layout"
import SEO from "../components/editions/2020/seo"
import Nav from "../components/editions/2020/nav"
import MobileNav from "../components/editions/2020/mobileNav"
import MansionsBlock from "../components/editions/2020/mansionsBlock"
import DesignersBlock from "../components/editions/2020/designersBlock"
import PartnersBlock from "../components/editions/2020/partnersBlock"
import Intro from "../components/editions/2020/intro"
import TextBlock from "../components/editions/2020/textBlock"
import VideoBlock from "../components/editions/2020/videoBlock"

const IndexPage = ({ data: { page } }) => {
  return (
    <Layout>
      <SEO title={page.seo.title} />
      <Visible xl xxl>
        <Nav />
      </Visible>
      <Visible xs sm md lg>
        <MobileNav />
      </Visible>
      <Wrapper>
        <Intro page={page} />
        {page.content.map((section, index) => (
          <section key={section.id} id={`section` + index}>
            {section.model.apiKey === "text_block" && (
              <TextBlock content={section} />
            )}
            {section.model.apiKey === "mansions_block" && (
              <MansionsBlock content={section} />
            )}
            {section.model.apiKey === "designers_block" && (
              <DesignersBlock content={section} />
            )}
            {section.model.apiKey === "partners_block" && (
              <PartnersBlock content={section} />
            )}
            {section.model.apiKey === "video_block" && (
              <VideoBlock content={section} />
            )}
          </section>
        ))}
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div`
  padding-top: 100px;
  & > * {
    margin-bottom: 6rem;
    &:last-child {
      margin-bottom: none;
    }
  }
`

export default IndexPage

export const query = graphql`
  query EditionPageQuery {
    page: datoCmsHomePage2020 {
      id
      title
      subtitle
      label
      seo {
        title
        description
      }
      heroImage {
        url
        alt
      }
      content {
        ... on DatoCmsVideoBlock {
          id
          title
          video {
            url
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsTextBlock {
          id
          text
          image {
            url
            alt
          }
          model {
            apiKey
          }
          fullWidth
        }
        ... on DatoCmsMansionsBlock {
          id
          title
          text
          mansions {
            id
            name
            url
            image {
              url
              alt
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsDesignersBlock {
          id
          title
          designers {
            id
            name
            url
            available
            availableAt
            image {
              url
              alt
            }
            mansion {
              name
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsPartnersBlock {
          id
          title
          partners {
            id
            name
            text
            logo {
              url
              alt
            }
            url
          }
          model {
            apiKey
          }
        }
      }
    }
  }
`
