import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "react-grid-system"
import DesignTokens from "./designTokens"
import RichContentBlockText from "./richContentBlockText"

const TextBlock = ({ content }) => (
  <Wrapper>
    <Container>
      {content.fullWidth ? (
        <Row justify="center">
          <FullWidth md={9} xs={12}>
            <RichContentBlockText html={content.text} />
            {content.image && <img src={content.image.url} alt={content.image.alt} />}
          </FullWidth>
        </Row>
      ) : (
        <Row justify="center">
          <Col md={5} xs={12}>
            {content.image && <img src={content.image.url} alt={content.image.alt} />}
          </Col>
          <Col md={7} xs={12}>
            <RichContentBlockText html={content.text} />
          </Col>
        </Row>
      )}
    </Container>
  </Wrapper>
)

const FullWidth = styled(Col)`
  & > * {
    margin-bottom: 3rem;
    &:last-child {
      margin-bottom: none;
    }
  }
`

const Wrapper = styled.div`
  & > img {
    width: 100%;
  }
`

export default TextBlock
