import React, { useState } from "react"
import styled from "styled-components"
import * as Scroll from "react-scroll"
import DesignTokens from "./designTokens"
import logo from "../../../images/logo-dimore.png"
import { Container } from "react-grid-system"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInstagram, faFacebook } from "@fortawesome/free-brands-svg-icons"
import { OutboundLink } from "../../home/link"
import { Link } from "gatsby"
import { motion, AnimatePresence } from "framer-motion"
import { X, Menu } from "react-feather"
import { LinkButton } from "./button"

let ScrollLink = Scroll.Link
let scroll = Scroll.animateScroll

const MobileNav = ({ locale, theme }) => {
  const [showMenu, setShowMenu] = useState(false)
  return (
    <Wrapper>
      <StyledMenu>
        <Logo>
          <img src={logo} alt="Wenda Logo" />
        </Logo>
        <div>
          <Button
            role="button"
            tabIndex={0}
            onClick={() => setShowMenu(!showMenu)}
            onKeyDown={() => setShowMenu(!showMenu)}
          >
            <Menu />
          </Button>
        </div>
      </StyledMenu>
      <AnimatePresence>
        {showMenu && (
          <StyledOverlay
            initial={{ opacity: 0, scale: 1.2 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.15 }}
            exit={{ opacity: 0 }}
          >
            <header>
              <X size={32} onClick={() => setShowMenu(false)} />
            </header>
            <LinksWrapper>
              <ScrollLink
                activeClass="active"
                to="header"
                spy={true}
                smooth={true}
                offset={-150}
                duration={500}
                onClick={() => setShowMenu(!showMenu)}
              >
                . Home
              </ScrollLink>
              <ScrollLink
                activeClass="active"
                to="section1"
                spy={true}
                smooth={true}
                offset={-150}
                duration={500}
                onClick={() => setShowMenu(!showMenu)}
              >
                . Bergamo
              </ScrollLink>
              <ScrollLink
                activeClass="active"
                to="section2"
                spy={true}
                smooth={true}
                offset={-150}
                duration={500}
                onClick={() => setShowMenu(!showMenu)}
              >
                . Dimore storiche
              </ScrollLink>
              <ScrollLink
                activeClass="active"
                to="section3"
                spy={true}
                smooth={true}
                offset={-150}
                duration={500}
                onClick={() => setShowMenu(!showMenu)}
              >
                . Le Puntate con i Designers
              </ScrollLink>
              <ScrollLink
                activeClass="active"
                to="section5"
                spy={true}
                smooth={true}
                offset={-150}
                duration={500}
                onClick={() => setShowMenu(!showMenu)}
              >
                . Organizzatori
              </ScrollLink>
              <OutboundLink
                href="https://spark.adobe.com/page/tDHM3DBPUqumP/"
                target="blank"
                rel="nofollow noopener"
              >
                . Press
              </OutboundLink>
              <LinkButton to="/newsletter/">Iscriviti</LinkButton>
            </LinksWrapper>
          </StyledOverlay>
        )}
      </AnimatePresence>
    </Wrapper>
  )
}

const Wrapper = styled.nav`
  position: fixed;
  z-index: 1;
  width: 100%;
  background: #fff;
`

const Logo = styled(ScrollLink)`
  /* position: absolute; */
  height: 30px;
  height: 30px;
`

const CustomContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${DesignTokens.spacing[6]} 0;
`

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > a {
    color: ${DesignTokens.colors.white};
    font-size: ${DesignTokens.fontSizes[5]};
    margin-bottom: ${DesignTokens.spacing[4]};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 70px;
`

const StyledMenu = styled.div`
  padding: 0 ${DesignTokens.spacing[3]} 0 ${DesignTokens.spacing[3]};
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  & > div {
    display: flex;
    height: 100%;
  }
  img {
    width: 120px;
  }
`

const StyledOverlay = styled(motion.div)`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  color: ${DesignTokens.colors.white};
  background: ${DesignTokens.colors.black};
  z-index: 10;
  header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 70px;
    text-align: right;
    padding: 0 1.2rem;
  }
  & > ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    & > li {
      font-size: ${DesignTokens.fontSizes[7]};
      padding: ${DesignTokens.spacing[3]};
      text-align: center;
      margin: 0;
      & > a {
        font-family: "Yantramanav";
        color: ${DesignTokens.colors.white};
        text-decoration: none;
      }
    }
  }
`

export default MobileNav
