import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { Heading } from "./typography"
import RichContentBlockText from "./richContentBlockText"

const SectionHeader = ({ title, text }) => (
  <Section>
    <Heading>{title}</Heading>
    {text && <RichContentBlockText html={text} />}
  </Section>
)
const Section = styled.section`
  text-align: center;
  margin-bottom: 3rem;
  h3 {
    margin-bottom: 1rem;
  }
`

export default SectionHeader
