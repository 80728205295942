import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "react-grid-system"
import DesignTokens from "./designTokens"
import SectionHeader from "./sectionHeader"

const VideoBlock = ({ content }) => (
  <Wrapper>
    <Container>
      <Row>
        <Col md={12}>
          <SectionHeader title={content.title} />
        </Col>
      </Row>
      <Row justify="center">
        <Col md={8}>
          <VideoWrapper>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/Abt-jI_mCJ0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </VideoWrapper>
        </Col>
      </Row>
    </Container>
  </Wrapper>
)

const Wrapper = styled.div`
`

const VideoWrapper = styled.div`
  position: relative;
  width:100%;
  height:0px;
  padding-bottom:56.25%;
  iframe {
    position: absolute;
    left:0;
    top:0;
    width:100%;
    height:100%
  }
`

export default VideoBlock
